@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    font-family: 'Cinzel', serif;
  }
  p {
    font-family: 'Assistant', sans-serif;
  }
}